(function () {
	'use strict';
	angular.module('eventvods')
		.controller('TWPlayerController', ['API_BASE_URL', '$rootScope', '$http', '$routeParams', '$timeout', '$window', '$interval', '$location',
			function (API, $rootScope, $http, $routeParams, $timeout, $window, $interval, $location) {
				var vm = this,
					player = null,
					$player = $('#player'),
					$frame,	startAt, playAll;

				vm.setup = {
					loading: true,
				};

				vm.gameIndex = parseInt($routeParams.game || 1) - 1;
				// checks if autoplay param is defined, otherwise false.
				// converts to truthy value using ~str.indexOf and finally bool using !!
				playAll = !!~($routeParams.autoplay || "true").indexOf('true');
				startAt = $routeParams.start || "picksBans";

				switch(startAt.toLowerCase()){
					case "gamestart":
					case "game":
						startAt = "gameStart";
						break;
					default:
						startAt = "picksBans";
						break;
				}

				vm.start = function(){
					player.seek(0);
				}

				vm.draft = function(){
					var url = vm.data.data[vm.gameIndex].twitch.picksBans;
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseTwitch(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seek(vm.setup.time);
						})
						return;
					}
					var start = parseTwitch(url);
					if (!start) return displayError("Invalid video URL.", true);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seek(vm.setup.time);
				}
				vm.gameStart = function(){
					var url = vm.data.data[vm.gameIndex].twitch.gameStart;
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseTwitch(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seek(vm.setup.time);
						})
						return;
					}
					var start = parseTwitch(url);
					if (!start) return displayError("Invalid video URL.", true);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seek(vm.setup.time);
				}
				vm.gotoGame = function(index){
					vm.gameIndex = index;
					$location.search('game', (vm.gameIndex+1));
					if (vm.data.data[vm.gameIndex].placeholder) return displayError("Match complete - this is is just included as a placeholder to prevent spoilers.", true);
					var url = vm.data.data[vm.gameIndex].twitch[startAt];
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
								var start = parseTwitch(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seek(vm.setup.time).play();

						})
						return;
					}
					var start = parseTwitch(url);
					if (!start) return displayError("Invalid video URL.", true);
					$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seek(vm.setup.time).play();
				}
				vm.nextGame = function(){
					vm.gotoGame(vm.gameIndex + 1);
				}
				$rootScope.$on('$routeUpdate', function(){
					var newIndex = parseInt($routeParams.game || 1) - 1;
					if(newIndex == vm.gameIndex) return;
					delete vm.setup.error;
					vm.gotoGame(newIndex);
				})
				function displayError(message, stopVideo){
					vm.setup.loading = false;
					vm.setup.error = message;
					if(stopVideo) player.pauseVideo();
				}
				function playerSize() {
					$timeout(function () {
						var maxH = window.innerHeight - (100 + 70);
						var dWidth = window.innerWidth;
						var maxW = maxH * 16/9;
						if(dWidth < maxW) maxW = dWidth;
						vm.setup.width = maxW;
						vm.setup.height = maxW * 9/16;
						$('#player').css('width', vm.setup.width).css('height', vm.setup.height);
						if(player !== null){
							player.setWidth(vm.setup.width);
							player.setHeight(vm.setup.height);
						}
					}, 0);
				};
				function timeToSeconds(time) {
					if (typeof time === "undefined") return 0;
					time = /((\d+)h)?((\d+)m)?((\d+)s)?/i.exec(time);
					for (var i = 0; i < time.length; i++) {
						if (typeof time[i] === "undefined") time[i] = 0;
					}
					return (parseInt(time[2] * 3600) + parseInt(time[4] * 60) + parseInt(time[6]));
				}
				function parseTwitch(link) {
					var tw = /https?:\/\/(?:www.)?twitch.tv\/videos\/(\d+)(?:\?t=(\S+))?/i.exec(link);
					if (tw == null) return false;
					else return {
						vid: 'v'+tw[1],
						time: timeToSeconds(tw[2])
					}
				}
				function initPlayer() {
					$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
					vm.setup.loading = false;
					angular.element(document).duScrollTo(0, 100, 1000);
					$timeout(function(){
						var options = {
							width: vm.setup.width,
							height: vm.setup.height,
							video: vm.setup.video
						};
						player = new Twitch.Player("twitch-player", options);
						player.addEventListener(Twitch.Player.READY, function(){
							player.setVideo(vm.setup.video, vm.setup.time);
						})
					}, 0);
				}
				function switchVideo(video, time){
					vm.setup.video = video;
					vm.setup.time = time;
					initPlayer();
				}

				playerSize();

				$(window).on("resize orientationchange ", playerSize);
				$http.get(API + '/match/' + $routeParams.match)
					.then(function (res) {
						vm.data = res.data;
						$rootScope.oldGame = $rootScope.game || null;
						$rootScope.game = $routeParams.gameslug;
						document.title = $rootScope.meta.title = vm.data.team1.name + ' vs ' + vm.data.team2.name + ' - ' + vm.data.event.shortTitle + (vm.data.event.subtitle ? ', ' + vm.data.event.subtitle : '') + ' - Eventvods';
						$rootScope.meta.description = "Watch " + vm.data.team1.name + " vs " + vm.data.team2.name + " during " + vm.data.event.shortTitle + (vm.data.event.subtitle ? ', ' + vm.data.event.subtitle : '') + ". Watch all esports matches easily and spoiler-free on Eventvods.";
						var url = vm.data.data[vm.gameIndex].twitch[startAt];
						//Temporary setup
						if(url.indexOf('ev.wtf') > -1){
							$http.get(url.replace('http:','https:')+'?json=true')
							.then(function(response){
								var start = parseTwitch(response.data.link);
								if (!start) return displayError("Unable to parse that video URL.");
								vm.setup.video = start.vid;
								vm.setup.time = start.time;
								console.log(vm.setup);
								initPlayer();
							})
							return;
						}
						var start = parseTwitch(url);
						if (!start) return displayError("Unable to parse that video URL.");
						vm.setup.video = start.vid;
						vm.setup.time = start.time;
						initPlayer();
					}, function (err) {
						displayError(err.message);
						$rootScope.meta.status = 404;
					});

			}
		]);
}());
